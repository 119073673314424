import * as React from "react";
import * as style from "./styles/contactSuccess.module.scss";
import { graphql, Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection";
import MutableCursorArea from "components/MutableCursorArea";

import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';

const ContactSuccessPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("contactSuccess.seo.title")}
      seoDescription={t("contactSuccess.seo.description")}
    >
      <HeaderSection
        className={style.ContentContactSuccess}
        title={
          <>
            <MarkEmailReadTwoToneIcon />
            {t("contactSuccess.title")}
          </>
        }
        description={
          <div className={style.ContactSuccess_description}>
            <p>{t("contactSuccess.description")}</p>
            <p className={style.ContactSuccess_descriptionLink}>
              <MutableCursorArea Tag={Link} to="/">
                {t("contactSuccess.goHome")}
              </MutableCursorArea>
            </p>
          </div>
        }
      />
    </PageWrapper>
  );
};

export default ContactSuccessPage;

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
